import { isBrowser } from '@fontanka/browser-or-node';
import { Config, BaseHttpClient, PageResult, Result } from '../base-http-client';
import { BaseParams } from '../params';
import { WeatherPageDTO } from './weather-page-dto';

export type WeatherPageParams = BaseParams;

export class WeatherHttpClient extends BaseHttpClient {
  private readonly _weatherPageUrl = '/pages/fontanka/weather/';

  constructor(config: Config) {
    super(config);
  }

  public async fetchWeatherPageData(
    params: WeatherPageParams
  ): Promise<Result<WeatherPageDTO>> {
    const { headers } = params;
    const url = `${this.host}${this._weatherPageUrl}`;

    const withCredentials = isBrowser() ? true : undefined;

    const response = await this.httpClient.get<PageResult<WeatherPageDTO>>(url, {
      headers,
      withCredentials,
      params: {
        regionId: this.regionId
      }
    });

    return {
      data: response.data.result,
      headers: response.headers
    };
  }
}
