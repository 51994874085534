import { Injectable, Inject } from 'vue-typedi';
import { Getter, Mutation, State } from 'vuex-simple';

import { RootModule } from '../../../core';
import type { CommonInfo } from '../../domain';
import {
  CommonInfoMapper,
  OneSignalService,
  ONE_SIGNAL_SERVICE
} from '../../services';

export const COMMON_INFO_MODULE_NAMESPACE = ['common_info_module'];

@Injectable()
export class CommonInfoModule {
  @Inject(ONE_SIGNAL_SERVICE)
  public oneSignalService: OneSignalService;

  constructor(private _root: RootModule) {}

  @State()
  private _topBannerHeight: number;

  @Getter()
  public get bannerTopHeight(): number {
    return this._topBannerHeight;
  }

  @Getter()
  public get commonInfo(): CommonInfo {
    return CommonInfoMapper.toDO(
      this._root.pageSpec.config.common,
      this._root.pageSpec.data
    );
  }

  @Getter()
  public get hotNews() {
    return this.commonInfo.hotNews && this.commonInfo.hotNews.data
      ? this.commonInfo.hotNews.data
      : null;
  }

  @Getter()
  public get themes() {
    return this.commonInfo.themes && this.commonInfo.themes.data
      ? this.commonInfo.themes.data
      : null;
  }
  @Getter()
  public get plotsLine() {
    return this.commonInfo.plotLines && this.commonInfo.plotLines.data
      ? this.commonInfo.plotLines.data
      : null;
  }

  @Getter()
  public get archiveLinks() {
    return this.commonInfo.archiveLinks && this.commonInfo.archiveLinks.data
      ? this.commonInfo.archiveLinks.data
      : [];
  }

  @Getter()
  public get projects() {
    return this.commonInfo.projects && this.commonInfo.projects.data
      ? this.commonInfo.projects.data
      : [];
  }

  @Getter()
  public get companyNews() {
    return this.commonInfo.companyNews && this.commonInfo.companyNews.data
      ? this.commonInfo.companyNews.data
      : [];
  }

  @Getter()
  public get calendar() {
    return this.commonInfo.calendar?.data ?? [];
  }

  @Getter()
  public get fontankaPro() {
    return this.commonInfo.fontankaPro?.data ?? [];
  }

  @Getter()
  public get searchRubrics() {
    return this.commonInfo.searchRubrics?.data ?? [];
  }

  @Getter()
  public get covidWidget() {
    return this.commonInfo.covidWidget?.data ?? null;
  }

  @Getter()
  public get oneSignal() {
    return this.commonInfo.oneSignal?.data ?? null;
  }

  @Getter()
  public get stories() {
    return this.commonInfo.stories?.data ?? null;
  }

  @Getter()
  public get editorialOffice() {
    return this.commonInfo.editorialOffice?.data ?? null;
  }

  @Getter()
  public get auth() {
    return this.commonInfo.auth?.data ?? null;
  }

  @Mutation()
  public updateBannerTopHeight(val: number) {
    this._topBannerHeight = val;
  }

  public initOneSignal(isMobile: boolean, analiticsCb?: (e: string) => void): void {
    if (this.oneSignal) {
      this.oneSignalService.init(this.oneSignal, isMobile, analiticsCb);
    }
  }
}
