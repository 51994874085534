import { BaseParams } from '../params';
import { Config, BaseHttpClient } from '../base-http-client';
import {
  ActionResultPageResponse,
  ActionResultPageResult
} from './action-result-dtos';

export type ActionResultPageParams = BaseParams & {
  token: string;
  subscriberId: number;
  typeId?: number;
};

export class ActionResultHttpClient extends BaseHttpClient {
  private readonly _resultForSubscriptionsPageUrl = '/pages/fontanka/subscriptions/';

  private readonly _reactivateSubscriptionPageUrl =
    '/pages/fontanka/subscriptions/reactivation/';

  private readonly _unsubscribeForRegisterPageUrl =
    '/pages/fontanka/subscriptions/register';

  constructor(config: Config) {
    super(config);
  }

  async fetchSubscriptionsUnsubscribePageData(
    params: ActionResultPageParams
  ): Promise<ActionResultPageResult> {
    const { headers, token, subscriberId, typeId } = params;
    const url = `${this.host}${this._resultForSubscriptionsPageUrl}`;
    const options = {
      headers,
      withCredentials: true,
      params: {
        regionId: this.regionId,
        token,
        subscriberId,
        typeId
      }
    };

    const response = await this.httpClient.delete<ActionResultPageResponse>(
      url,
      options
    );

    return {
      data: response.data.result,
      headers: response.headers
    };
  }

  async fetchReactivateSubscriptionPageData(
    params: ActionResultPageParams
  ): Promise<ActionResultPageResult> {
    const { headers, token, subscriberId } = params;
    const url = `${this.host}${this._reactivateSubscriptionPageUrl}`;
    const options = {
      headers,
      withCredentials: true,
      params: {
        regionId: this.regionId,
        token,
        subscriberId
      }
    };

    const response = await this.httpClient.patch<ActionResultPageResponse>(
      url,
      null,
      options
    );

    return {
      data: response.data.result,
      headers: response.headers
    };
  }

  async fetchUnsubscribeForRegisterPageData(
    params: ActionResultPageParams
  ): Promise<ActionResultPageResult> {
    const { headers, token, subscriberId } = params;
    const url = `${this.host}${this._unsubscribeForRegisterPageUrl}`;
    const options = {
      headers,
      withCredentials: true,
      params: {
        regionId: this.regionId,
        token,
        subscriberId
      }
    };

    const response = await this.httpClient.delete<ActionResultPageResponse>(
      url,
      options
    );

    return {
      data: response.data.result,
      headers: response.headers
    };
  }
}
