






import { guardUnspecified } from '@portal/utils/util-guards';
import { getCookie, setCookie, removeCookie } from 'tiny-cookie';
import { Component, Prop, Vue } from 'vue-property-decorator';

import { Creative } from '../adv-slot/creative';
import { LazyHydrateSsr } from '../lazy-hydrate-ssr';

type AdvData = {
  data: Creative | string;
  path: string;
  settings: {
    place: string;
    placeId: number;
    sticky: boolean;
    subPlace: string;
    types: {
      desktop: boolean;
      laptop: boolean;
      mobile: boolean;
      tablet: boolean;
    };
  };
  type: string;
} | null;

const RECORD_WITHOUT_ADV = [71983220, 73014437, 73014698, 73015250];

const URL_WITHOUT_ADV = ['/__site/deyatelnost_v_sfere_it/'];

@Component({
  name: 'AdvFullscreen',
  components: {
    LazyHydrateSsr
  }
})
export default class AdvFullscreen extends Vue {
  @Prop({
    required: true,
    type: Object
  })
  adv1: AdvData;
  @Prop({
    type: Object,
    default: null
  })
  adv2: AdvData;
  @Prop({
    required: true,
    type: Boolean
  })
  needToSkipCounter: boolean;

  disabledFullscreenCookie = 'jtnews_fullscreen_disabled';
  conterFullscreenCookie = 'jtnews_fullscreen_counter';
  nextVariantFullscreenCookie = 'is_fullscreen_adunit_rotated';
  isCookieIncremented = false;
  maxDisplayCount = 2;
  timeOutFullscreen = 10800000; // 3h

  get advHtml() {
    return `
        <div id="fullscreen"></div>
        <script>
          window.yaContextCb = window.yaContextCb || [];
          var adfoxSettings = window.SDK_SETTINGS?.adfoxParams || {};
          var puid40 = document.querySelector('meta[property="fnt:rubric"]')?.content || '';
          var puid53 = document.querySelector('meta[property="fnt:page-type"]')?.content || '';
          if (puid53 === 'record') {
            var partnerStatId = this.adfoxSettings['partner-stat-id'] || 1001;
            var puid54 = this.adfoxSettings.puid54 || 'ump_a';
          }
          if (puid53 === 'comments') {
            var partnerStatId = this.adfoxSettings['partner-stat-id'] || 103;
            var puid54 = this.adfoxSettings.puid54 || 'ump_a';
          }

          var advDataFullscreen = ${JSON.stringify(this.adv1?.data)};
          if (${JSON.stringify(this.adv2)} !== null) {
            var variantFullscreen = Math.floor(Math.random() * 2) + 1;

            if (document.cookie.includes('${this.nextVariantFullscreenCookie}')) {
              variantFullscreen = document.cookie.includes('${this.nextVariantFullscreenCookie
      }=1') ? 1 : 2;
            }

            var nextVariantFullscreen = variantFullscreen === 1 ? 2 : 1;

            advDataFullscreen = variantFullscreen === 1 ? ${JSON.stringify(
        this.adv1?.data
      )} : ${JSON.stringify(this.adv2?.data)};
            document.cookie = '${this.nextVariantFullscreenCookie
      }=' + nextVariantFullscreen + '; path=/;';
          }

          var hasBlockerCookie = document.cookie.includes('${this.disabledFullscreenCookie
      }');

          var urlSearchParams = new URLSearchParams(window.location.search);
          var isPushReferrer = urlSearchParams.get('utm_source') === 'push' || urlSearchParams.get('utm_medium') === 'push';
          var unallowedAdvReferrers = ['iframe-toloka.com', 'raterhub.com', 'iframe-tasks.yandex', 'iframe-yang.yandex', 'yandex-team.ru'];
          var referrer = new URLSearchParams(window.location.search).get('_testReferrer') || document.referrer;
          var isUnallowedAdvReferrer = unallowedAdvReferrers.some(domain => referrer.includes(domain));

          if (!hasBlockerCookie && !isUnallowedAdvReferrer && !isPushReferrer) {
            window.yaContextCb.push(()=>{
              Ya.adfoxCode.create({
                ownerId: 320882,
                containerId: 'fullscreen',
                fullscreenBannerBlacklist: ['banner.transfer'],
                type: 'fullscreen',
                params: {
                  p1: advDataFullscreen.adfoxConfig.p1,
                  p2: advDataFullscreen.adfoxConfig.p2,
                  'partner-stat-id': partnerStatId,
                  puid40,
                  puid53,
                  puid54
                }
              });
            });
          }
          <\/script>
      `;
  }

  get recordId() {
    return this.$route.name === 'record' ? Number(this.$route.params.id) : 0;
  }

  get isRender(): boolean {
    if (RECORD_WITHOUT_ADV.includes(this.recordId)) {
      return false;
    }

    if (URL_WITHOUT_ADV.includes(this.$route.path)) {
      return false;
    }

    return true;
  }

  setDisabledFullscreenCookie() {
    const date = new Date(Date.now() + this.timeOutFullscreen);
    setCookie(this.disabledFullscreenCookie, 'true', {
      expires: date.toUTCString()
    });
  }

  incrementCookie() {
    if (this.needToSkipCounter) {
      return;
    }

    const disabledFullscreen = getCookie(this.disabledFullscreenCookie);
    const fullscreenCounter = getCookie(this.conterFullscreenCookie);

    if (!guardUnspecified(disabledFullscreen)) {
      const counter = Number(fullscreenCounter) + 1;
      const date = new Date(Date.now() + this.timeOutFullscreen);
      setCookie(this.conterFullscreenCookie, String(counter), {
        expires: date.toUTCString()
      });

      if (counter >= this.maxDisplayCount) {
        this.setDisabledFullscreenCookie();
      }

      this.isCookieIncremented = true;
    }
  }

  decrementCookie() {
    if (!this.isCookieIncremented) {
      return;
    }

    removeCookie(this.disabledFullscreenCookie);

    const fullscreenCounter = getCookie(this.conterFullscreenCookie);
    const counter = Math.max(Number(fullscreenCounter) - 1, 0);
    const date = new Date(Date.now() + this.timeOutFullscreen);
    setCookie(this.conterFullscreenCookie, String(counter), {
      expires: date.toUTCString()
    });
  }

  created() {
    const unwatch = this.$watch('needToSkipCounter', (value: boolean) => {
      if (value) {
        this.decrementCookie();
        unwatch();
      }
    });
  }

  mounted() {
    if (this.$route.query?.utm_source === 'push' || this.$route.query?.utm_medium === 'push') {
      this.setDisabledFullscreenCookie();
    }

    this.incrementCookie();
  }
}
