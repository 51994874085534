import { BaseHttpClient, Config, Result, PageResult } from '../base-http-client';
import { BaseParams } from '../params';

import { TagPageDTO } from './tag-page-dto';

export type TagPageParams = BaseParams & {
  url: string;
  tag: string;
  page: number;
  pagesize: number;
  rubric?: string;
  dateFrom?: string;
  dateTo?: string;
};

export class TagsHttpClient extends BaseHttpClient {
  private readonly _tagPageUrl = '/pages/fontanka/tag';

  constructor(config: Config) {
    super(config);
  }

  public async fetchTagPageData(
    params: TagPageParams
  ): Promise<Result<TagPageDTO | string>> {
    const { headers } = params;
    const url = `${this.host}${this._tagPageUrl}`;

    const response = await this.httpClient.get<PageResult<TagPageDTO>>(url, {
      headers,
      params: {
        regionId: this.regionId,
        ...params,
        headers: undefined
      }
    });

    return {
      data: response.data.result,
      headers: response.headers,
      status: response.data.status
    };
  }
}
