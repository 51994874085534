import { guardUnspecified } from '@smh/utils/guards';

import { CropType } from '@fontanka/cropping';
import type {
  RubricFixedRecordDTO,
  RubricPageDTO,
  RubricSearchResultDTO
} from '@fontanka/news-api-client';

import type { BaseRecord } from '../../../domain';
import { BaseRecordMapper } from '../../../services';

export type RubricPageSearchResult = {
  type: RubricSearchResultDTO['type'];
  data: BaseRecord[];
  total: number;
};

export type RubricPageFixedRecord = {
  type: RubricFixedRecordDTO['type'];
  data: BaseRecord | null;
};

export type RubricPageBlocks = {
  fixedRecord?: RubricPageFixedRecord;
  searchResult?: RubricPageSearchResult;
};

export class RubricPageMapper {
  public static toDO(pageDTO: RubricPageDTO): RubricPageBlocks {
    const blocks: RubricPageBlocks = {};
    return pageDTO.config.content.reduce((acc, current) => {
      const { data, meta } = pageDTO.data[current.path];

      if (current.type === 'fixed_record') {
        const fixedRecords = (data as RubricFixedRecordDTO['data']) ?? [];

        acc.fixedRecord = {
          type: current.type,
          data: guardUnspecified(fixedRecords[0])
            ? BaseRecordMapper.toDO(fixedRecords[0], {
                crop: true,
                cropType: CropType.Classic
              })
            : null
        };
      }

      if (current.type === 'search_result') {
        const block = data as RubricSearchResultDTO['data'];
        acc.searchResult = {
          type: current.type,
          total: meta?.total ?? 0,
          data: block.map(record => BaseRecordMapper.toDO(record)) ?? []
        };
      }
      return acc;
    }, blocks);
  }
}
