import { isBrowser } from '@fontanka/browser-or-node';

import { Config, BaseHttpClient, PageResult, Result } from '../base-http-client';
import { BaseParams } from '../params';

import { HomePageDTO } from './home-page-dto';

export type HomePageParams = BaseParams;

export class HomeHttpClient extends BaseHttpClient {
  private readonly _homePageUrl = '/pages/fontanka/main/';
  private readonly _newHomePageUrl = '/pages/fontanka/main/new/';

  constructor(config: Config) {
    super(config);
  }

  public async fetchHomePageData(
    params: HomePageParams
  ): Promise<Result<HomePageDTO>> {
    const { headers } = params;
    const url = `${this.host}${this._homePageUrl}`;

    const withCredentials = isBrowser() ? true : undefined;

    const response = await this.httpClient.get<PageResult<HomePageDTO>>(url, {
      headers,
      withCredentials,
      params: {
        regionId: this.regionId
      }
    });

    return {
      data: response.data.result,
      headers: response.headers
    };
  }

  public async fetchNewHomePageData(
    params: HomePageParams
  ): Promise<Result<HomePageDTO>> {
    const { headers } = params;
    const url = `${this.host}${this._newHomePageUrl}`;

    const withCredentials = isBrowser() ? true : undefined;

    const response = await this.httpClient.get<PageResult<HomePageDTO>>(url, {
      headers,
      withCredentials,
      params: {
        regionId: this.regionId
      }
    });

    return {
      data: response.data.result,
      headers: response.headers
    };
  }
}
