import { Headers } from '@fontanka/data';

export enum ArchiveType {
  Articles = 'article',
  News = 'news'
}

export type BaseParams = {
  headers: Headers;
};

export type StaticPageParams = BaseParams & {
  pageType: string;
};

export type DailyNewsPageParams = BaseParams & {
  rubric?: string;
  type?: ArchiveType;
  period?: number;
};

export type LastNewsPageParams = DailyNewsPageParams & {
  /**
   * 'dd.mm.yyyy'
   */
  date?: string;
  isCommercial?: 0 | 1;
};

export type RubricPageParams = BaseParams & {
  rubric: string;
  page: number;
  pagesize: number;
};

export type CalendarArchivePageParams = BaseParams & {
  isCommercial?: 0 | 1;
  rubric?: string;
  type?: ArchiveType;
  year: number;
};

export type ProjectsPageParams = BaseParams;

export type WidgetAutoParams = {
  count: number;
};
