import { isBrowser } from '@fontanka/browser-or-node';

import {
  ActionResultHttpClient,
  ActionResultPageParams
} from './action-result-http-client';
import {
  AllAuthorPageParams,
  AuthorHttpClient,
  AuthorPageDTO,
  AuthorPageParams
} from './author-http-client';
import { Config, BaseHttpClient, Result, PageResult } from './base-http-client';
import {
  CommentsHttpClient,
  AllCommentsPageParams,
  RecordCommentsPageParams
} from './comments-http-client';
import { CurrencyHttpClient, CurrencyPageParams } from './currency-http-client';
import {
  DailyNewsPageDTO,
  BasePageDTO,
  LastNewsPageDTO,
  RubricPageDTO,
  CalendarArchivePageDTO,
  ProjectsPageDTO
} from './dtos';
import { FormatPageParams, FormatsHttpClient } from './formats-http-client';
import { HomeHttpClient, HomePageParams } from './home-http-client';
import { MentionsHttpClient, MentionsPageParams } from './mentions-http-client';
import {
  DailyNewsPageParams,
  StaticPageParams,
  LastNewsPageParams,
  RubricPageParams,
  CalendarArchivePageParams,
  ProjectsPageParams,
  BaseParams
} from './params';
import {
  PhotosVideosHttpClient,
  PhotosVideosPageParams
} from './photos-videos-http-client';
import { ProfileHttpClient, ProfilePageParams } from './profile-http-client';
import {
  RecordsHttpClient,
  RecordPageParams,
  LongreadPageParams
} from './records-http-client';
import { SearchHttpClient, SeachPageParams } from './search-http-client';
import { TagPageParams, TagsHttpClient } from './tags-http-client';
import { ThemesHttpClient, ThemePageParams } from './themes-http-client';
import { WeatherHttpClient, WeatherPageParams } from './weather-http-client';

export class NewsApiClient extends BaseHttpClient {
  private readonly _formatsHttpClient: FormatsHttpClient;

  private readonly _themesHttpClient: ThemesHttpClient;

  private readonly _currencyHttpClient: CurrencyHttpClient;

  private readonly _mentionsHttpClient: MentionsHttpClient;

  private readonly _homeHttpClient: HomeHttpClient;

  private readonly _authorHttpClient: AuthorHttpClient;

  private readonly _commentsHttpClient: CommentsHttpClient;

  private readonly _searchHttpClient: SearchHttpClient;

  private readonly _recordsHttpClient: RecordsHttpClient;

  private readonly _photosVideosHttpClient: PhotosVideosHttpClient;

  private readonly _weatherHttpClient: WeatherHttpClient;

  private readonly _profileHttpClient: ProfileHttpClient;

  private readonly _actionResultHttpClient: ActionResultHttpClient;

  private readonly _tagsHttpClient: TagsHttpClient;

  private readonly _staticPageUrl = '/pages/fontanka/static';

  private readonly _dailyNewsPageUrl = '/pages/fontanka/daily_news';

  private readonly _lastNewsPageUrl = '/pages/fontanka/last_news';

  private readonly _rubricPageUrl = '/pages/fontanka/rubrics/';

  private readonly _archivePageUrl = '/pages/fontanka/archive/';

  private readonly _projectsPageUrl = '/pages/fontanka/formats/';

  constructor(config: Config) {
    super(config);
    this._formatsHttpClient = new FormatsHttpClient(config);
    this._themesHttpClient = new ThemesHttpClient(config);
    this._currencyHttpClient = new CurrencyHttpClient(config);
    this._mentionsHttpClient = new MentionsHttpClient(config);
    this._homeHttpClient = new HomeHttpClient(config);
    this._authorHttpClient = new AuthorHttpClient(config);
    this._commentsHttpClient = new CommentsHttpClient(config);
    this._searchHttpClient = new SearchHttpClient(config);
    this._recordsHttpClient = new RecordsHttpClient(config);
    this._photosVideosHttpClient = new PhotosVideosHttpClient(config);
    this._weatherHttpClient = new WeatherHttpClient(config);
    this._profileHttpClient = new ProfileHttpClient(config);
    this._actionResultHttpClient = new ActionResultHttpClient(config);
    this._tagsHttpClient = new TagsHttpClient(config);
  }

  public async fetchStaticPageData(
    params: StaticPageParams
  ): Promise<Result<BasePageDTO>> {
    const { headers, pageType } = params;
    const url = `${this.host}${this._staticPageUrl}`;

    const withCredentials = isBrowser() ? true : undefined;

    const response = await this.httpClient.get<PageResult<BasePageDTO>>(url, {
      headers,
      withCredentials,
      params: {
        regionId: this.regionId,
        pageType
      }
    });

    return {
      data: response.data.result,
      headers: response.headers
    };
  }

  public async fetchHomePageData(params: HomePageParams) {
    return this._homeHttpClient.fetchHomePageData(params);
  }

  public async fetchNewHomePageData(params: HomePageParams) {
    return this._homeHttpClient.fetchNewHomePageData(params);
  }

  public async fetchRecordPageData(params: RecordPageParams) {
    return this._recordsHttpClient.fetchRecordPageData(params);
  }

  public async fetchLongreadPageData(params: LongreadPageParams) {
    return this._recordsHttpClient.fetchLongreadPageData(params);
  }

  public async fetchDailyNewsPageData(
    params: DailyNewsPageParams
  ): Promise<Result<DailyNewsPageDTO>> {
    const { rubric, type, headers, period } = params;
    const url = `${this.host}${this._dailyNewsPageUrl}`;

    const withCredentials = isBrowser() ? true : undefined;

    const response = await this.httpClient.get<PageResult<DailyNewsPageDTO>>(url, {
      headers,
      withCredentials,
      params: {
        regionId: this.regionId,
        rubric,
        type,
        period
      }
    });

    return {
      data: response.data.result,
      headers: response.headers
    };
  }

  public async fetchLastNewsPageData(
    params: LastNewsPageParams
  ): Promise<Result<LastNewsPageDTO>> {
    const { type, rubric, isCommercial, date, headers } = params;
    const url = `${this.host}${this._lastNewsPageUrl}`;

    const withCredentials = isBrowser() ? true : undefined;

    const response = await this.httpClient.get<PageResult<LastNewsPageDTO>>(url, {
      headers,
      withCredentials,
      params: {
        regionId: this.regionId,
        type,
        rubric,
        isCommercial,
        date
      }
    });

    return {
      data: response.data.result,
      headers: response.headers
    };
  }

  public async fetchRubricPageData(
    params: RubricPageParams
  ): Promise<Result<RubricPageDTO>> {
    const { rubric, headers, page, pagesize } = params;
    const url = `${this.hostV2}${this._rubricPageUrl}${rubric}`;

    const withCredentials = isBrowser() ? true : undefined;
    const response = await this.httpClient.get<PageResult<RubricPageDTO>>(url, {
      headers,
      withCredentials,
      params: {
        regionId: this.regionId,
        rubric,
        page,
        pagesize
      }
    });

    return {
      data: response.data.result,
      headers: response.headers
    };
  }

  public async fetchFormatPageData(params: FormatPageParams) {
    return this._formatsHttpClient.fetchFormatPageData(params);
  }

  public async fetchThemePageData(params: ThemePageParams) {
    return this._themesHttpClient.fetchThemePageData(params);
  }

  public async fetchCalendarArchivePageData(
    params: CalendarArchivePageParams
  ): Promise<Result<CalendarArchivePageDTO>> {
    const { year, type, isCommercial, rubric, headers } = params;
    const url = `${this.host}${this._archivePageUrl}`;

    const withCredentials = isBrowser() ? true : undefined;

    const response = await this.httpClient.get<PageResult<CalendarArchivePageDTO>>(
      url,
      {
        headers,
        withCredentials,
        params: {
          regionId: this.regionId,
          year,
          type,
          isCommercial,
          rubric
        }
      }
    );

    return {
      data: response.data.result,
      headers: response.headers
    };
  }

  public async fetchProjectsPageData(
    params: ProjectsPageParams
  ): Promise<Result<ProjectsPageDTO>> {
    const { headers } = params;
    const url = `${this.host}${this._projectsPageUrl}`;

    const withCredentials = isBrowser() ? true : undefined;

    const response = await this.httpClient.get<PageResult<ProjectsPageDTO>>(url, {
      headers,
      withCredentials,
      params: {
        regionId: this.regionId
      }
    });

    return {
      data: response.data.result,
      headers: response.headers
    };
  }

  public async fetchMentionsPageData(params: MentionsPageParams) {
    return this._mentionsHttpClient.fetchMentionsPageData(params);
  }

  public async fetchCurrencyPageData(params: CurrencyPageParams) {
    return this._currencyHttpClient.fetchCurrencyPageData(params);
  }

  public async fetchAuthorPageData(params: AuthorPageParams) {
    return this._authorHttpClient.fetchAuthorPageData(params);
  }

  public async fetchAllAuthorsPageData(
    params: AllAuthorPageParams
  ): Promise<Result<AuthorPageDTO>> {
    return this._authorHttpClient.fetchAllAuthorsPageData(params);
  }

  public async fetchWeatherPageData(params: WeatherPageParams) {
    return this._weatherHttpClient.fetchWeatherPageData(params);
  }

  public async fetchAllCommentsPageData(params: AllCommentsPageParams) {
    return this._commentsHttpClient.fetchAllCommentsPageData(params);
  }

  public async fetchSearchPageData(params: SeachPageParams) {
    return this._searchHttpClient.fetchSearchPageData(params);
  }

  public async fetchPhotosPageData(params: PhotosVideosPageParams) {
    return this._photosVideosHttpClient.fetchPhotosPageData(params);
  }

  public async fetchVideosPageData(params: PhotosVideosPageParams) {
    return this._photosVideosHttpClient.fetchVideosPageData(params);
  }

  public async fetchRecordCommentsPageData(params: RecordCommentsPageParams) {
    return this._commentsHttpClient.fetchRecordCommentsPageData(params);
  }

  public async fetchProfilePageData(params: ProfilePageParams) {
    return this._profileHttpClient.fetchProfilePageData(params);
  }

  public async fetchEditProfilePageData(params: BaseParams) {
    return this._profileHttpClient.fetchEditProfilePageData(params);
  }

  public async fetchSubscriptionsUnsubscribePageData(
    params: ActionResultPageParams
  ) {
    return this._actionResultHttpClient.fetchSubscriptionsUnsubscribePageData(
      params
    );
  }

  public async fetchReactivateSubscriptionPageData(params: ActionResultPageParams) {
    return this._actionResultHttpClient.fetchReactivateSubscriptionPageData(params);
  }

  public async fetchUnsubscribeForRegisterPageData(params: ActionResultPageParams) {
    return this._actionResultHttpClient.fetchUnsubscribeForRegisterPageData(params);
  }

  public async fetchTagPageData(params: TagPageParams) {
    return this._tagsHttpClient.fetchTagPageData(params);
  }
}
