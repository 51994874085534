import { componentFactory } from 'vue-tsx-support';

import type { ArchiveRecord } from '../../_items';
import { ArchiveRecordItem } from '../../_items';

import styles from './search-records-list.styles.scss';

type RecordInfo = {
  recordId: number;
  isCommercial: boolean;
  place: number;
};

export default componentFactory.create({
  props: {
    records: {
      type: Array as () => ArchiveRecord[],
      required: true as const
    }
  },
  methods: {
    clickTitle(record: RecordInfo) {
      this.$emit('clickTitle', record);
    },
    clickRubric(record: RecordInfo) {
      this.$emit('clickRubric', record);
    },
    clickComments(record: RecordInfo) {
      this.$emit('clickComments', record);
    },
    viewRecord(record: RecordInfo): void {
      this.$emit('viewRecord', record);
    }
  },
  render() {
    return (
      <div>
        {this.records.length > 0 ? (
          this.records.map((record, index) => (
            <ArchiveRecordItem
              class={styles.item}
              usePublishDate={true}
              record={record}
              onClickComments={() =>
                this.clickComments({
                  recordId: record.id,
                  isCommercial: record.isCommercial ?? false,
                  place: index + 1
                })
              }
              onClickRubric={() =>
                this.clickRubric({
                  recordId: record.id,
                  isCommercial: record.isCommercial ?? false,
                  place: index + 1
                })
              }
              onClickTitle={() =>
                this.clickTitle({
                  recordId: record.id,
                  isCommercial: record.isCommercial ?? false,
                  place: index + 1
                })
              }
              onVisible={() =>
                this.viewRecord({
                  recordId: record.id,
                  isCommercial: record.isCommercial ?? false,
                  place: index + 1
                })
              }
            />
          ))
        ) : (
          <span class={styles.noResults}>Ничего не найдено.</span>
        )}
      </div>
    );
  }
});
