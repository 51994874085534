import {
  BlockConfig,
  BlockInfo,
  BaseProjectDTO,
  BaseThemeHeaderDTO,
  CompanyNewsItemDTO,
  ImageDTO,
  BaseRecordDTO,
  LeadDTO,
  BaseRubricDTO,
  BaseStoriesDTO,
  BasePlotLineDTO
} from '../../BaseDTOs';

export type ReachGoalsParamsType = {
  [key: string]: string | ReachGoalsParamsType;
};

export enum CommonBlockType {
  ArchiveLinks = 'archive_links',
  Auth = 'auth',
  Calendar = 'calendar',
  CompanyNewsBottom = 'company_news_bottom',
  CovidWidget = 'covid19_statistics',
  EditorialOffice = 'editorial_office',
  FontankaPro = 'fontanka_pro',
  HotNews = 'hot_news',
  OneSignal = 'onesignal_config',
  Projects = 'projects',
  ReachGoal = 'reach_goal',
  SearchRubrics = 'search_rubrics',
  Stories = 'stories',
  Themes = 'themes',
  PlotLines = 'plot_lines',
  WindowRubrics = 'window_rubrics'
}

export type CommonConfigDTO = {
  archive_links?: BlockConfig<CommonBlockType.ArchiveLinks>;
  auth?: BlockConfig<CommonBlockType.Auth>;
  calendar?: BlockConfig<CommonBlockType.Calendar>;
  company_news_bottom?: BlockConfig<CommonBlockType.CompanyNewsBottom>;
  covid19_statistics?: BlockConfig<CommonBlockType.CovidWidget>;
  editorial_office?: BlockConfig<CommonBlockType.EditorialOffice>;
  fontanka_pro: BlockConfig<CommonBlockType.FontankaPro>;
  hot_news?: BlockConfig<CommonBlockType.HotNews>;
  onesignal_config?: BlockConfig<CommonBlockType.OneSignal>;
  projects?: BlockConfig<CommonBlockType.Projects>;
  reach_goal?: BlockConfig<CommonBlockType.ReachGoal>;
  search_rubrics?: BlockConfig<CommonBlockType.SearchRubrics>;
  stories?: BlockConfig<CommonBlockType.Stories>;
  themes?: BlockConfig<CommonBlockType.Themes>;
  plot_lines?: BlockConfig<CommonBlockType.PlotLines>;
  window_rubrics?: BlockConfig<CommonBlockType.WindowRubrics>;
};

export type CommonCompanyNewsDTO = BlockInfo<
  CompanyNewsItemDTO[] | null,
  CommonBlockType.CompanyNewsBottom
>;

export type HotNewsItemDTO = {
  id: number;
  text: string;
  url: string;
  type: 'topic' | 'urgent';
};

export type ArchiveLinksItemDTO = {
  link: string;
  title: string;
};

export type ArchiveLinksDTO = BlockInfo<
  ArchiveLinksItemDTO[],
  CommonBlockType.ArchiveLinks
>;

export type HotNewsDTO = BlockInfo<HotNewsItemDTO | null, CommonBlockType.HotNews>;

export type CommonProjectsDTO = BlockInfo<
  BaseProjectDTO[],
  CommonBlockType.Projects
>;

export type CommonThemesDTO = BlockInfo<
  BaseThemeHeaderDTO[],
  CommonBlockType.Themes
>;

export type CommonPlotLinesDTO = BlockInfo<
  BasePlotLineDTO[],
  CommonBlockType.PlotLines
>;

export type CommonCalendarItemDTO = BaseRecordDTO & {
  lead: LeadDTO;
  headerKeywords: string[];
  isCommentsAllowed: boolean;
  mainPhoto: ImageDTO;
  publishAt: string;
  publishedAgo: string;
  subheader: string;
  commentsCount: number;
};

export type CommonCalendarDTO = BlockInfo<
  CommonCalendarItemDTO[] | null,
  CommonBlockType.Calendar
>;

export type CommonFontankaProItemDTO = BaseRecordDTO & {
  leadForNews: string;
  viewType: 'business_tribune' | 'fontanka_pro';
  lead?: LeadDTO;
  mainPhoto: ImageDTO;
  publishedAgo?: string;
  hasVideoMark?: boolean;
  hasMainPhotoCommercialLabel?: boolean;
};

export type CommonFontankaProDTO = BlockInfo<
  CommonFontankaProItemDTO[],
  CommonBlockType.FontankaPro
>;

export type WindowRubricsDTO = BlockInfo<
  BaseRubricDTO[],
  CommonBlockType.WindowRubrics
>;

export type SearchRubricsDTO = BlockInfo<
  BaseRubricDTO[],
  CommonBlockType.SearchRubrics
>;

export type CovidInfoDTO = {
  city: string;
  cured: number;
  curedDiff: number;
  died: number;
  diedDiff: number;
  infected: number;
  infectedDiff: number;
};

export type CovidRecordDTO = {
  header: string;
  id: number;
  urls: {
    url: string;
    urlComments: string;
  };
};

export type CovidWidgetDTO = BlockInfo<
  {
    link: string;
    covid19Info: CovidInfoDTO[];
    record: CovidRecordDTO;
  } | null,
  CommonBlockType.CovidWidget
>;

export type ReachGoalDTO = BlockInfo<
  | {
      name: string;
      params?: string | ReachGoalsParamsType;
    }[]
  | null,
  CommonBlockType.ReachGoal
>;

export type OneSignalConfigDTO = {
  appId: string;
  segment: string;
};

export type OneSignalConfigBlockDTO = BlockInfo<
  OneSignalConfigDTO | null,
  CommonBlockType.OneSignal
>;

export type CommonStoriesDTO = BlockInfo<BaseStoriesDTO[], CommonBlockType.Stories>;

export type AuthBlockDTO = {
  ok: string;
  fb: string;
  google: string;
  vk: string;
  ya: string;
  rules: string;
  terms: string;
  offer: string;
  register: string;
  recover: string;
  personalData: string;
};

export type CommonAuthDTO = BlockInfo<AuthBlockDTO | null, CommonBlockType.Auth>;

export type CommonBlocks =
  | CommonCompanyNewsDTO
  | ArchiveLinksDTO
  | HotNewsDTO
  | CommonProjectsDTO
  | CommonThemesDTO
  | CommonCalendarDTO
  | CommonFontankaProDTO
  | WindowRubricsDTO
  | SearchRubricsDTO
  | CovidWidgetDTO
  | ReachGoalDTO
  | OneSignalConfigBlockDTO
  | CommonStoriesDTO
  | CommonAuthDTO;

export type SocialLinks = {
  telegram: string;
  viber: string;
  vk: string;
  odnoklassniki: string;
  whatsapp: string;
  instagram: string;
};

export type Feedback = {
  phone?: string;
  phoneText?: string;
  prePhoneLabel?: string;
  preSocialLinksLabel?: string;
  altPhone?: string;
  altPhoneText?: string;
  socialLinksDesktop: SocialLinks;
  socialLinksMobile: SocialLinks;
  text: string;
};

export type FeedbackDTO = BlockInfo<Feedback, CommonBlockType.EditorialOffice>;

export type FontankaProArticleVM = {
  id: number;
  header: string;
  leadStr: string;
  hasVideo: boolean;
  hasPhoto: boolean;
  hasAudio: boolean;
  publishAt: string;
  isCommentsAllowed: boolean;
  commentsCount: number;
  canShowComments: boolean;
  urls: {
    url: string;
    urlComments: string;
  };
  isCommercial: boolean;
  image: {
    url: string;
  } | null;
  hasMainPhotoCommercialLabel: boolean;
};
